import React from 'react';
import Data from '../data.js';
import Items from './items.js'
import ScrollMagic from 'scrollmagic'
import { TweenMax, ScrollToPlugin } from "gsap/all";
import Footer from './footer';

class Menu_mobile extends React.Component{

  constructor(props){
    super();
    this.menuEl = [];
    this.categories = [];
    this.controller = new ScrollMagic.Controller();
    this.navTranslater = React.createRef();
    this.nav = React.createRef();
    this.state = {
      navIsFixed: false
    }
  }

  componentDidMount(){

    var scene = new ScrollMagic.Scene({triggerElement: this.nav.current, triggerHook: 0})
    								.setPin(this.nav.current)
    								.addTo(this.controller);


    this.manageNav();
    let prev = window.scrollY;

    window.addEventListener("scroll", (e) => {
      const window = e.currentTarget;
      let nav = this.nav.current.getBoundingClientRect();
      if(this.nav.current.style.position == "fixed"){
        if (prev > window.scrollY) {
            this.nav.current.style.height = "100px"
        } else if (prev < window.scrollY) {
            this.nav.current.style.height = "0px"
        }
      }


      prev = window.scrollY;
    })

  }

  manageNav(){
    this.menuEl.map( (e, index) => {
      if(e.current != null){
        this.insertScrollDetection(e, index);
      }
    })
  }

  insertScrollDetection(e, index){
    var scene = new ScrollMagic.Scene({
                      triggerElement: e.current,
                      offset: 100
                    })
                    .on("enter", () => {
                      this.selectCategory(index)
                    })
                    .on("leave", () => {
                      this.selectCategory(index - 1)

                    })
                    .addTo(this.controller);
  }

  returnNav(){
    return Data.map( (e, index, array) => {
      let ref = React.createRef();
      if(this.categories.length < array.length){
        this.categories.push(ref)
      }
      return <p onClick={()=> {this.scroll(index)}} ref={ref} key={index}>{e.category}</p>
    })
  }

  returnMenu(){
      return Data.map( (e, index) => {
      let ref = React.createRef();
      this.menuEl.push(ref);
      return <div key={index} ref={ref} className="menu-el">
      <h3>{e.category}</h3>
        <Items
        items = {e.items}
        />
      </div>
    })
  }

  selectCategory(index){
    this.categories.map( (e, i) => {
      if(e.current != null){
        if(index === i){
          this.categories[index].current.style.backgroundColor = "#3b6425"
          this.categories[index].current.style.color = "white"

        }
        else{
          e.current.style.backgroundColor = "white"
          e.current.style.color = "black"

        }
      }
    })

  }



  scroll(index){
    console.log("SCROLL", this.nav.current.getBoundingClientRect())
    window.scrollTo(0, this.menuEl[index].current.offsetTop - this.nav.current.getBoundingClientRect().height)
    this.selectCategory(index)

  }
  openWindow(){
    window.open("https://photayho.clusterpos.com/", '_blank')
  }
  openSocialMedia(link){
    window.open(link, '_blank')

  }



  render(){
    return(
      <div className="container">
        <div className="header">

            <a className="adresse" href="#google-container"> <p>6414 St Denis, Montreal</p></a>
            <p>(514) 273-5627</p>
            <span class="glf-button commander-button" onClick={() => {this.openSocialMedia('https://photayho.order-online.ai/#/')}}>Commande en Ligne</span>
        </div>

      <div class="image-intro">
        <img src="./images/background3.JPG"></img>
      </div>
        <div ref={this.nav} className="nav-bar">
          <div className="nav-title">
            <h1>Pho Tay Ho (6414 St-Denis)</h1>
          </div>
          <div id="nav"  className="nav">
          <div ref={this.navTranslater} className="nav-translator">
          {this.returnNav()}
          </div>
          </div>
        </div>

        <div>
          <div className="info">
            <p>Restaurant Information</p>
            <div className="info-body">
              <div class="info-body-info">
              <p>6414 St Denis, Montreal, QC H2S 2R7</p>
              <a href="https://www.google.com/maps/place/Pho+Tay+Ho/@45.5336596,-73.6068345,17z/data=!3m1!4b1!4m5!3m4!1s0x4cc9196ee49d9f9b:0xbf8b20269bb1705c!8m2!3d45.5336596!4d-73.6046458" target="_blank"> <p>More Info</p></a>
              </div>
              <div className="social-media">
                <img onClick={() => { this.openSocialMedia("https://www.instagram.com/photayho_mtl/?igshid=1nhkxxv0x2ddb")}} src="./images/ig_logo.png"></img>
                <img onClick={() => { this.openSocialMedia("https://m.facebook.com/pages/category/Pho-Restaurant/Restaurant-Pho-Tay-Ho-132154773474344/")}}  src="./images/fb_logo.png"></img>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="menu">
            <p className='menu-title'>Menu</p>
            {this.returnMenu()}
          </div>
        </div>
        <div className="message">
          <p>Les prix sont sujets à changement sans préavis</p>
        </div>
        <Footer />

      </div>
    )
  }
}

export default Menu_mobile;
