import React from 'react';
import Data from '../data.js';
import '../styles/items.css'

class Items extends React.Component{

  componentDidMount(){
      
  }


  returnItems(){
    const { items } = this.props
    return items.map( (e, index) => {
      return <div className="item" key={index}><p className="item-title">{e.name}</p>
      <p className="item-desc">{e.desc}</p>
      <p className="item-price">${e.price}</p>
      </div>
    })
  }


  render(){
    return(
      <>
      {this.returnItems()}
      </>
    )
  }
}

export default Items;
