import React from 'react';



class googleMaps extends React.Component{


constructor(props){
    super();
 }
    
  componentDidMount(){
      
  }


  render(){
    return(
      <div class="google">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2794.82835306344!2d-73.60683984883126!3d45.53365957899927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc9196ee49d9f9b%3A0xbf8b20269bb1705c!2sPho%20Tay%20Ho!5e0!3m2!1sen!2sca!4v1591215949445!5m2!1sen!2sca" width="100%" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      </div>
    )
  }
}

export default googleMaps
