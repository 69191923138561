import React from 'react';
import Data from '../data.js';
import Items_desktop from './items_desktop'
import GoogleMaps from './googleMaps';
import Footer from './footer';

class Menu_desktop extends React.Component {

  constructor(props){
    super()
  }



  returnMenu(){
    return Data.map((e)=>{
      return <div className="menu-el-desktop">
      <h1>{e.category}</h1>
      <Items_desktop
      items = {e.items}
      />
      </div>
    })
  }

  openWindow(){
    window.open("https://photayho.clusterpos.com/", '_blank')
  }

  openSocialMedia(link){
    window.open(link, '_blank')

  }



  render(){
    return(
      <div className="container-desktop">
        <div className="accueil"> 

          <div className="header">
            <div class="commander">
            <span class="glf-button commander-button" onClick={() => {this.openSocialMedia('https://photayho.order-online.ai/#/')}}>Commande en Ligne</span>
            </div>
            <div class="info">
            <a className="adresse" href="#google-container"> <p>6414 St Denis, Montreal</p></a>
            <p>(514) 273-5627</p>
            </div>
          </div>
         

          <div className="img-container">
            <img src='./images/background2.jpg'></img>
          </div>
        </div>
        <div className="menu-header">
          <h1>Cuisine Vietnamienne</h1>
          <p> Specialite Soupe Tonkinoise</p>
        </div>

        <div className="contacts">
          <p>6414 St Denis, Montreal, QC H2S 2R7</p> 
          <a href="#google-container"> <p className="more">More Info</p></a>
          <div className="social-media">
            <img onClick={() => { this.openSocialMedia("https://www.instagram.com/photayho_mtl/?igshid=1nhkxxv0x2ddb")}} src="./images/ig_logo.png"></img>
            <img onClick={() => { this.openSocialMedia("https://m.facebook.com/pages/category/Pho-Restaurant/Restaurant-Pho-Tay-Ho-132154773474344/")}}  src="./images/fb_logo.png"></img>
          </div>
        </div>
        <div className="menu-desktop">
        {this.returnMenu()}
        </div>
        <div id="google-container">
        <GoogleMaps/>
        </div>
         <div className="message">
            <p>Les prix sont sujets à changement sans préavis</p>
          </div>
        <Footer />
      </div>
    )
  }

}

export default Menu_desktop
