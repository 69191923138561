import React from 'react';
import Data from '../data.js';

class Items_desktop extends React.Component{


  constructor(props){
    super();
  }
  componentDidMount(){

  }


  returnItems(){
    const { items } = this.props
    return items.map( (e, index) => {
      return <div className="item_desktop">
        <div className="item_desktop-body">
          <p className="name">{e.name}</p>
          <p className="desc">{e.desc}</p>
        </div>
        <p className="price">{e.price}</p>
      </div>
    })
  }


  render(){
    return(
      <>
      {this.returnItems()}
      </>
    )
  }
}

export default Items_desktop;
