import React from 'react';
import Menu_mobile from './components/menu_mobile.js';
import Menu_desktop from './components/menu_desktop.js';

import './styles/style.css'
class App extends React.Component {

  constructor(props){
    super();
   
  }

  returnMenu(){
    const x = window.matchMedia("(min-width: 320px) and (max-width: 480px) and (orientation: portrait)")
    if(x.matches){
      return <Menu_mobile/>
    }
    else{
      return <Menu_desktop/>
    }         
  }

  render(){
    return (
      <div className="App">
        {this.returnMenu()}
      </div>
    )
  }

}

export default App;
