const data = [
{
    "category": "Entrée / Appetizer",
    "items": [
      {
        "name": "1. Gỏi Cuốn (1) / Gỏi Cuốn Chay",
        "desc": "1 Rouleau printemps avec crevettes et poulet ou végétarien salade avec sauce d’arachides / 1 Spring roll with shrimps & chicken or vegetable, salad and peanut sauce",
        "price": "4.50 (Végé) / 5.50 (Crevette & Poulet)"
      },
      {
        "name": "2. Chả Giò",
        "desc": "2 Rouleaux Impériaux (porc) / 2 Imperial Rolls (pork)",
        "price": "5.50"
      },
      {
        "name": "3. Bò Tái Chanh",
        "desc": "Salade de Bœuf Saignant au citron / Sliced rare Beef salad with lemon",
        "price": "19.00"
      },
      {
        "name": "4. Cánh Gà, Lòng Gà",
        "desc": "Ailes de poulet bouilli avec Abats de Poulet / Boiled Chicken Wings with Chicken Giblets",
        "price": "10.00 / 18.00"
      },
      {
        "name": "5. Gỏi Gà, Lòng Gà",
        "desc": "Salade aux poulet avec Abats de Poulet / Chicken salad with Chicken Giblets",
        "price": "10.00 / 18.00"
      },
      {
        "name": "6. Gà Luộc Nguyên Con (1), hoặc nửa con (1), Đūi Gà (1)",
        "desc": "1 Poulet bouilli ou ½ ou cuisse de poulet bouilli / 1 Boiled chicken or ½ or Boiled Chicken Drumstick",
        "price": "38.00 / 20.00 / 12.00"
      }
    ]
},
{
  "category": "Soupe Tonkinoise / Tonkinese Soup",
  "items": [
    {
      "name": "7. Ðặc Biệt Phở Tây Hồ",
      "desc": "Bœuf Saignant, flanc bien cuit, boulette de bœuf / Rare beef, well done, flank, beef balls.",
      "price": "L/16.50 - XL/17.50"
    },
    {
      "name": "8. Phở Tái Chín",
      "desc": "Bœuf Saignant, flanc bien cuit / Rare beef, Well done Flank",
      "price": "L/16.50 - XL/17.50"
    },
    {
      "name": "9. Phở Chín",
      "desc": "Flanc bien cuit / Well done Flank",
      "price": "L/16.50 - XL/17.50"
    },
    {
      "name": "10. Phở Tái",
      "desc": "Bœuf Saignant / Rare beef",
      "price": "L/16.50 - XL/17.50"
    },
    {
      "name": "11. Phở Bò Viên",
      "desc": "Boulette de bœuf / Beef balls",
      "price": "L/16.50 - XL/17.50"
    },
    {
      "name": "12. Bò Viên (extra)",
      "desc": "Boulette de bœuf / Beef balls",
      "price": "5.00"
    },
    {
      "name": "13. Phở Gà",
      "desc": "Au poulet / Chicken Tonkinese Soup",
      "price": "L/16.50 - XL/17.50"
    },
    {
      "name": "14. Phở Gà Tái",
      "desc": "Au poulet & Bœuf saignant / Chicken and rare Beef",
      "price": "L/16.50 - XL/17.50"
    },
    {
      "name": "15. Phở Gà Lòng Gà Tái",
      "desc": "Au poulet avec Abats de Poulet & bœuf saignant / Chicken with Chicken Giblets and rare beef",
      "price": "L/16.50 - XL/17.50"
    },
    {
      "name": "16. Phở Gà Lòng Gà",
      "desc": "Au poulet avec Abats de Poulet / Chicken with Chicken Giblets",
      "price": "L/16.50 - XL/17.50"
    },
    {
      "name": "17. Bún Gà LòngGà",
      "desc": "Soupe au Vermicelles au poulet avec Abats de Poulet / Chicken Vermicelli Soup with Chicken Giblets",
      "price": "L/16.50 - XL/17.50"
    },
    {
      "name": "18. Phở Gà Hoặc Bò Áp Chảo Với Rau (Phở Nước)",
      "desc": "Au poulet ou bœuf sauté aux légumes / chicken or beef with sauted vegetables",
      "price": "L/17.50 - XL/18.50"
    },
    {
      "name": "19. Phở Áp Chảo Với Rau",
      "desc": "Soupe Tonkinoise sauté aux légumes / Tonkinese Soup with sauted vegetables",
      "price": "L/16.00 - XL/17.00"
    },
    {
      "name": "20. Phở Tôm Với Rau",
      "desc": "Au crevettes & sauté aux légumes / with shrimps & sauted vegetables ",
      "price": "L/18.00 - XL/19.00"
    },
  ]
},
{
  "category": "Vermicelle / Vermicelli",
  "items": [
    {
      "name": "21. Thịt Heo Nướng Giả Cầy",
      "desc": "Jarret Porc Grillé servi avec vermicelles, salade Tay Ho & sauce de pâte de crevette / Grilled Knuckle pork served with vermicelli, salad Tay Ho & shrimp paste sauce ",
      "price": "22.00"
    },
    {
      "name": "22. Chả Cá Tây Hồ",
      "desc": "Poisson grillé Tây Hồ sauté à l’aneth servi avec vermicelles, salade Tay Ho & sauce de pâte de crevette / Grilled fish Tây Hồ sautéed with dill served with vermicelli, salad Tay Ho & shrimp paste sauce",
      "price": "29.50"
    },
    {
      "name": "23. Bún Chả Giò",
      "desc": "Vermicelles aux rouleaux impériaux(6) & salade / Imperial Roll (6) on vermicelli & salad",
      "price": "18.00"
    },
    {
      "name": "24. Bún Thịt Nướng, Chả Giò",
      "desc": "2 Rouleaux impériaux, porc grillé servi avec vermicelles & salade / 2 Imperial rolls, grilled pork served with vermicelli & salad",
      "price": "20.00"
    },
    {
      "name": "25. Bún Thịt Nướng",
      "desc": "Porc grillé servi avec vermicelles & salade / Grilled pork served with vermicelli & salad",
      "price": "18.00"
    },
    {
      "name": "26. Bún Chả Nướng Tây Hồ",
      "desc": "Porc BBQ servi avec vermicelles & salade style Tây Hồ  / BBQ pork served with vermicelli & salad style Tây Hồ",
      "price": "22.00"
    },
    {
      "name": "27. Bún Tôm Nướng, Thịt Nướng",
      "desc": "Brochettes de crevettes, porc grillé servi avec vermicelles & salade / Skewer of grilled shrimps, pork served with vermicelli & salad",
      "price": "21.00"
    },
    {
      "name": "28. Bún Tôm Thịt Nưóng Chả Giò",
      "desc": "Brochettes de crevettes, porc grillés, 2 rouleaux impériaux servis avec vermicelles & salade / Skewers of grilled shrimps, grilled pork, 2 imperial rolls with vermicelli & Salad",
      "price": "22.00"
    },
    {
      "name": "29. Bún Tôm Gà Nướng,Chả Giò",
      "desc": "Brochettes de crevettes, poulet grillé, 2 rouleaux impériaux servis avec vermicelles & salade / Skewers of grilled shrimps, grilled Chicken, 2 imperial rolls served with vermicelli & salad",
      "price": "22.00"
    },
    {
      "name": "30. Bún Hoặc Cơm Gà Nướng",
      "desc": "Poulet grillé servi avec vermicelles ou riz & salade / Grilled Chicken served with vermicelli or rice & salad",
      "price": "19.00"
    },
    {
      "name": "31. Bún Hoặc Cơm Gà Nướng, Chả Giò",
      "desc": "Poulet grillé, 2 rouleaux impériaux servis avec vermicelles ou riz & salade / Grilled chicken, 2 imperial rolls served with vermicelli or rice & salad",
      "price": "21.00"
    },
    {
      "name": "32. Bún Bò Hoặc Gà Xào Xã",
      "desc": "Bœuf ou poulet sauté à la citronnelle, servis avec vermicelles & salade / Sautéed beef or chicken with lemongrass, served with vermicelli & salad",
      "price": "19.00"
    },
    {
      "name": "33. Bún Bò Hoặc Gà Xào Xã, Chả Giò",
      "desc": "Bœuf ou poulet sauté à la citronnelle, 2 rouleaux impériaux servis avec vermicelles & salade / Sautéed beef or chicken with lemongrass, 2 imperial rolls served with vermicelli & salad",
      "price": "21.00"
    },
    {
      "name": "34. Bún Hoặc Cơm Bò Nướng",
      "desc": "Bœuf grillé servies avec vermicelles ou riz & salade / Grilled beef served with vermicelli or rice & salad",
      "price": "19.00"
    },
    {
      "name": "35. Bún Hoặc Cơm Tôm, Bò Nướng, Gà Nướng",
      "desc": "Brochettes de crevettes grillées, bœuf grillé, poulet grillé servis avec vermicelles ou riz & salade / Skewers of grilled shrimps, grilled beef, grilled chicken served with vermicelli or rice & salad",
      "price": "23.00"
    },
    {
      "name": "36. Bún Hoặc Cơm Tôm Nướng",
      "desc": "2 Brochettes de crevettes grillées servies avec vermicelles ou riz & salade / 2 Skewers of grilled shrimps served with vermicelli or rice & salad",
      "price": "21.00"
    },
  ]
},
{
  "category": "Riz / Rice",
  "items": [
    {
      "name": "37. Cơm Sườn Nướng",
      "desc": "2 Côtelettes de porc grillées servi avec du riz & salade / 2 grilled pork chop with rice & salad",
      "price": "19.00"
    },
    {
      "name": "38. Cơm Sườn Nướng, Trửng Gà Ốp la",
      "desc": "1 Côtelettes de porc grillé, œuf servies avec du riz & salade 1 Grilled pork chop, egg served with rice & salad",
      "price": "19.00"
    },
    {
      "name": "39. Cơm Tôm Sườn Nướng",
      "desc": "Brochette de crevettes, côtelettes de porc grillé servis avec riz & salade / Skewer of grilled shrimp, pork chop, served with rice & salad",
      "price": "21.00"
    },
    {
      "name": "40. Cơm Tôm, Sườn Nướng,Trửng Gà Ốp La",
      "desc": "Brochettes de crevettes, côtelettes de porc grillé, œuf servies avec du riz & salade / Skewer of grilled shrimp, pork chop, served with rice & salad",
      "price": "22.00"
    },
    {
      "name": "41. Cơm Tôm, Gà Nướng",
      "desc": "Brochette de crevettes, poulet grillé servies avec riz & salade / Skewer of grilled shrimp, chicken served with rice & salad ",
      "price": "21.00"
    },
    {
      "name": "42. Cơm Gà Nướng, Sườn Nướng",
      "desc": "Poulet grillé, côtelette de porc grillé servies avec du riz & salade / Skewer of grilled chicken, pork chop served with rice & salad",
      "price": "19.00"
    }
  ]
},
{
  "category": "Café/ Coffee",
  "items": [
    {
      "name": "43. Cà Phê Phin Nóng",
      "desc": "Café noir expresso (chaud) / Expresso black coffee (hot)",
      "price": "5.50"
    },
    {
      "name": "44. Cà Phê Sửa Nóng",
      "desc": "Café au lait condensé (chaud) / Expresso condense milk coffee (hot)",
      "price": "5.50"
    },
    {
      "name": "45. Cà Phê Phin Đen Đá",
      "desc": "Café glacé expresso noir / Expresso black iced coffee",
      "price": "5.50"
    },
    {
      "name": "46. Cà Phê Phin Sửa Đá",
      "desc": "Café glacé expresso au lait condensé / Expresso condense milk iced coffee",
      "price": "5.50"
    }
  ]
},
{
  "category": "Boisson / Drink",
  "items": [
    {
      "name": "47. Nước Chanh Vắt",
      "desc": "Limonade / Fresh lemonade",
      "price": "5.50"
    },
    {
      "name": "48. Coke / 7up / Coke diet / Ginger Ale / Nestea / Club Soda",
      "desc": "",
      "price": "2.50"
    },
    {
      "name": "49. Dừa Xiêm",
      "desc": "Jus coco / Fresh coconut juice",
      "price": "5.50"
    },
    {
      "name": "50. Chè 3 Mầu",
      "desc": "Combinaison des fèves avec gelés et lait de coco / Bean with jelly and coconut milk",
      "price": "6.00"
    },
    {
      "name": "51. Soda Chanh Đường",
      "desc": "Limonade pétillent sucrée / Soda sweet lemonade ",
      "price": "6.00"
    },
    {
      "name": "52. Thé Jasmin",
      "desc": "",
      "price": "2.00"
    },
    {
      "name": "53. Bouteille d'eau",
      "desc": "",
      "price": "2.75"
    },
  ]
},
{
  "category": "Dessert",
  "items": [
    {
      "name": "54. Chuối Chiên",
      "desc": "Banane frit / Fried banana",
      "price": "5.00"
    },
  ]
},
{
  "category": "Suppléments",
  "items": [
    {
      "name": "Soupe hàng ngày",
      "desc": "Soupe du jour / Daily soup",
      "price": "5.50"
    },
    {
      "name": "Nước dùng",
      "desc": "Bol de bouillon / Bowl of broth ",
      "price": "4.00 / 8.00"
    },
    {
      "name": "Cơm, Bún, Bánh Tráng",
      "desc": "Riz, Vermicelles, Feuille de riz / Rice, Vermicelli, rice paper",
      "price": "4.00"
    },
    {
      "name": "Rau xào",
      "desc": "Légumes Sauté / Sautéed Vegetables",
      "price": "5.00 / 7.00"
    },
    {
      "name": "Bánh phồng tôm",
      "desc": "Chips aux crevettes / Shrimp chips",
      "price": "4.00"
    },
    {
      "name": "Bánh đa",
      "desc": "Galette au sésame / Crispy rice cracker",
      "price": "4.00"
    },
    {
      "name": "Trứng ốp la",
      "desc": "2 œufs / 2 eggs",
      "price": "4.00"
    },
    {
      "name": "Extra thịt",
      "desc": "Extra viandes (meat)",
      "price": "6.00"
    },
    {
      "name": "Sườn nướng",
      "desc": "1 Côtelette de porc grillé / 1 Grilled pork chop",
      "price": "7.50"
    },
    {
      "name": "Bò nướng",
      "desc": "1 Brochette de bœuf / 1 Beef skewer",
      "price": "7.50"
    },
    {
      "name": "Gà nướng",
      "desc": "1 Brochette de poulet / 1 Chicken skewer",
      "price": "7.50"
    },
    {
      "name": "Tôm nướng",
      "desc": "1 Brochette de crevette / 1 Shrimps skewer",
      "price": "8.50"
    },
    {
      "name": " Salade Tây Hồ",
      "desc": "Salad Tây Hồ (herbes vietnamiens)",
      "price": "7.00"
    },
    {
      "name": "Chả viên",
      "desc": "6 Boullettes de porc BBQ / 6 BBQ pork meat balls",
      "price": "10.00"
    },
    {
      "name": "Chén thịt bún chả",
      "desc": "Bol de porc BBQ / BBQ pork",
      "price": "13.00"
    },
    {
      "name": "Chén thịt giả cầy",
      "desc": " Jarret porc grillé / grilled knuckle of pork",
      "price": "13.00"
    },
    {
      "name": "Chén chả cá",
      "desc": "Poisson grillé / Grilled fish",
      "price": "19.00"
    },
    {
      "name": "Mắm tôm",
      "desc": "Sauce pâte de crevettes / Shrimp paste sauce",
      "price": "3.50"
    },
    {
      "name": "Nước mắm",
      "desc": "Sauce poisson / Fish sauce",
      "price": "2.00"
    },
    {
      "name": "Salade, Ngò, Rau quế, Giá",
      "desc": "Salade, Coriandre, Basilic et Fèves germés",
      "price": "1.00"
    },
    {
      "name": "Ajout d’un extra assiette / bol à partager",
      "desc": "",
      "price": "1.00"
    },
  ]
}
]
export default data;
