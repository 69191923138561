import React, { Component } from 'react'

export class footer extends Component {
    render() {
        return (
            <div className="footer"> 
                <div className="modalites">
                    <p><a href="/files/photayho_modalites_et_conditions.pdf" download>modalités et conditions</a></p>
                    <p><a href="/files/photayho_terms_and_conditions.pdf" download>terms and conditions</a></p>
                </div>
            </div>
        )
    }
}
export default footer
